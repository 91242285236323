import { defineStore } from 'pinia'
import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export const popupStore = defineStore('cyoPopupModule', {
    state: () => {
        return {
            ring_cyo_popup: false,
            earring_cyo_popup: false,
            pendant_cyo_popup: false,
            subscribe_cyo_popup: false,
            cookie_cyo_popup: false,
        }
    },
    getters: { ...getters },
    actions: {
        ...actions, commitMutation: function (mutationToCall, data) {

            try {
                mutations[mutationToCall](this, data)
            } catch (error) {
                console.error('POPUP MUTATION ERROR - ', mutationToCall, data, error)
                throw error
            }
        }
    },
    persist: true,
})
